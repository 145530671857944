<template>
	<div class="clue-applet">
		<div class="content-box">
			<div class="search-box">
				<el-form :inline="true" :model="formSearch" class="demo-form-inline" label-width="100px">
					<el-form-item label="举报对象名称">
						<el-input v-model="formSearch.companyName" placeholder="请输入举报对象名称"></el-input>
					</el-form-item>
					<el-form-item style="text-align: right;" class="fr">
						<el-button type="primary" @click="searchNameFn">检索</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="table-box">
				<el-table border v-loading="loadingFlag" empty-text="加载中..." :data="tableData" @selection-change="handleSelectionChange" @sort-change='sortChange' :default-sort="{prop: 'createTime', order: 'descending'}" :row-style="{height:'48px'}" style="width: 100%;">
					<el-table-column type="selection" width="30"></el-table-column>
					<el-table-column type="index" :index="typeIndex" label="序号" width="50"></el-table-column>
					<el-table-column prop="reportType" label="举报对象类型" width="100"></el-table-column>
					<el-table-column prop="reportedName" label="举报对象名称" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="" label="举报对象所在区域" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							<span>{{scope.row.provinceName}} {{scope.row.cityName}} {{scope.row.areaName}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="reportAddr" label="举报对象详细地址" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="reportIndustry" label="举报对象所属行业" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="financingType" label="集资类型" width="150"></el-table-column>
					<el-table-column prop="investCapital" label="投入金额(元)" width="100"></el-table-column>
					<el-table-column prop="isAffirm" label="是否纳入监测" width="100">
						<template slot-scope="scope">
							<span v-if="scope.row.isAffirm == 0">未监测</span>
							<span v-if="scope.row.isAffirm == 1">已监测</span>
						</template>
					</el-table-column>
					<el-table-column prop="informer" label="举报人" width="100"></el-table-column>
					<el-table-column prop="createTime" label="举报时间" width="150" sortable="custom"></el-table-column>
					<el-table-column prop="" label="操作" width="50">
						<template slot-scope="scope">
							<i class="iconfont icon-chakan3 color-m" title="查看" @click="handleLook(scope.$index, scope.row)" style="cursor: pointer;margin: 0 3px;"></i>
						</template>
					</el-table-column>
					<template slot="empty">
						<img v-if="dataTableEmpty" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
					</template>
				</el-table>
				<el-button v-show="totalItems != 0" type="primary" size="mini" @click="exportONE" style="float: left;margin-top:10px;margin-right: 20px;">{{exportOneName}}</el-button>
				<el-button v-show="totalItems != 0" type="primary" size="mini" @click="exportALLFn" style="float: left;margin-top:10px;">{{exportAllName}}</el-button>
				<el-pagination @current-change="handleCurrentChange" :total="totalItems" :current-page="currentPage" :page-size="pageSize" layout="total, prev, pager, next, jumper"></el-pagination>
			</div>
		</div>
		<!-- 查看   弹框 -->
		<el-dialog title="举报详情" :visible.sync="dialogVisible" width="80%">
			<div style="width: 100%;height: 640px;">
				<el-scrollbar style="height: 100%;width:100%;">
					<el-form :model="ruleForm" label-width="180px" class="demo-ruleForm" style="padding-right: 15px;">
						<h4 class="title-h4">举报对象信息</h4>
						<el-row :gutter="0">
							<el-col :span="8">
								<el-form-item label="举报对象名称" prop="reportedName">
									<el-input v-model="ruleForm.reportedName" readonly></el-input>
								</el-form-item>
								<el-form-item label="举报对象类型" prop="reportType">
									<el-input v-model="ruleForm.reportType" readonly></el-input>
								</el-form-item>
								<el-form-item label="举报对象所在区域" prop="diqu">
									<el-input v-model="ruleForm.diqu" readonly></el-input>
								</el-form-item>
								<el-form-item label="举报对象详细地址" prop="reportAddr">
									<el-input v-model="ruleForm.reportAddr" readonly></el-input>
								</el-form-item>
								<el-form-item label="举报对象所属行业" prop="reportIndustry">
									<el-input v-model="ruleForm.reportIndustry" readonly></el-input>
								</el-form-item>
								<el-form-item label="集资类型" prop="financingType">
									<el-input v-model="ruleForm.financingType" readonly></el-input>
								</el-form-item>
								<el-form-item label="投入金额(元)" prop="investCapital">
									<el-input v-model="ruleForm.investCapital" readonly></el-input>
								</el-form-item>
								<el-form-item label="收益类型" prop="revenueType">
									<el-input v-model="ruleForm.revenueType" readonly></el-input>
								</el-form-item>
								<el-form-item label="利率" prop="rate">
									<el-input v-model="ruleForm.rate" readonly></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8">	
								<el-form-item label="关联APP名称" prop="relationalAppName">
									<el-input v-model="ruleForm.relationalAppName" readonly></el-input>
								</el-form-item>
								<el-form-item label="关联网站名称" prop="relationalWebsiteName">
									<el-input v-model="ruleForm.relationalWebsiteName" readonly></el-input>
								</el-form-item>
								<el-form-item label="关联联系公众号" prop="relationalPublicAccount">
									<el-input v-model="ruleForm.relationalPublicAccount" readonly></el-input>
								</el-form-item>
								<el-form-item label="关联联系微信号" prop="relationalWeixinAccount">
									<el-input v-model="ruleForm.relationalWeixinAccount" readonly></el-input>
								</el-form-item>
								<el-form-item label="关联联系QQ号" prop="relationalQqAccount">
									<el-input v-model="ruleForm.relationalQqAccount" readonly></el-input>
								</el-form-item>
								<el-form-item label="关联联系电话" prop="relationalPhoneNumber">
									<el-input v-model="ruleForm.relationalPhoneNumber" readonly></el-input>
								</el-form-item>
								<el-form-item label="关联主体工商" prop="relationalBusinessInfo">
									<el-input v-model="ruleForm.relationalBusinessInfo" readonly></el-input>
								</el-form-item>
								<el-form-item label="发案时间" prop="caseTime">
									<el-input v-model="ruleForm.caseTime" readonly></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8">	
								<el-form-item label="是否涉及互联网" prop="involvingInternet">
									<el-input v-model="ruleForm.involvingInternet" readonly></el-input>
								</el-form-item>
								<el-form-item label="是否承诺保本保息" prop="promisePrincipal">
									<el-input v-model="ruleForm.promisePrincipal" readonly></el-input>
								</el-form-item>
								<el-form-item label="已知集资规模(万元)" prop="financingMoney">
									<el-input v-model="ruleForm.financingMoney" readonly></el-input>
								</el-form-item>
								<el-form-item label="已知参与人数(人)" prop="participantsNumber">
									<el-input v-model="ruleForm.participantsNumber" readonly></el-input>
								</el-form-item>
								<el-form-item label="已知最早开始时间" prop="financingStartTime">
									<el-input v-model="ruleForm.financingStartTime" readonly></el-input>
								</el-form-item>
								<el-form-item label="已知最早暴雷时间" prop="financingBreakTime">
									<el-input v-model="ruleForm.financingBreakTime" readonly></el-input>
								</el-form-item>
								<el-form-item label="是否跨省" prop="transprovincial">
									<el-input v-model="ruleForm.transprovincial" readonly></el-input>
								</el-form-item>
								<el-form-item label="已知涉及地区" prop="relationalArea">
									<el-input v-model="ruleForm.relationalArea" readonly></el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-form-item label="具体情况说明" prop="remark">
							<el-input v-model="ruleForm.remark" readonly type="textarea"></el-input>
						</el-form-item>
						<el-form-item label="风险标签" prop="riskLebal">
							<el-input v-model="ruleForm.riskLebal" readonly type="textarea"></el-input>
						</el-form-item>
						<h4 class="title-h4">举报人信息</h4>
						<el-row :gutter="0">
							<el-col :span="24">
								<el-form-item label="是否实名" prop="anonymity" style="width: 33.3%;float: left;">
									<el-input v-model="ruleForm.anonymity" readonly></el-input>
								</el-form-item>
								<el-form-item label="举报时间" prop="reportTime" style="width: 33.3%;float: left;">
									<el-input v-model="ruleForm.reportTime" readonly></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="举报人姓名" prop="informer" style="width: 33.3%;float: left;">
									<el-input v-model="ruleForm.informer" readonly></el-input>
								</el-form-item>
								<el-form-item label="举报人身份证号" prop="informerIdCard" style="width: 33.3%;float: left;">
									<el-input v-model="ruleForm.informerIdCard" readonly></el-input>
								</el-form-item>
								<el-form-item label="举报人手机号" prop="informerPhoneNumber" style="width: 33.3%;float: left;">
									<el-input v-model="ruleForm.informerPhoneNumber" readonly></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="举报人邮箱" prop="informerEmail" style="width: 33.3%;float: left;">
									<el-input v-model="ruleForm.informerEmail" readonly></el-input>
								</el-form-item>
								<el-form-item label="举报人QQ号" prop="informerQqAccount" style="width: 33.3%;float: left;">
									<el-input v-model="ruleForm.informerQqAccount" readonly></el-input>
								</el-form-item>
								<el-form-item label="举报人微信号" prop="informerWeixinAccount" style="width: 33.3%;float: left;">
									<el-input v-model="ruleForm.informerWeixinAccount" readonly></el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<h4 class="title-h4">附件</h4>
						<p v-for="(item,index) in ruleForm.imageIds" :key="index" style="line-height: 40px;text-align: left;padding-left: 95px;">
							<a :href="urlStr+item" target="_blank">{{urlStr+item}}</a>
						</p>
						<!-- <el-form-item label="" prop="imageIds">
							<p v-for="(item,index) in ruleForm.imageIds" :key="index" style="line-height: 40px;text-align: left;">
								<a :href="urlStr+item" target="_blank">{{urlStr+item}}</a>
							</p>
						</el-form-item> -->
					</el-form>
				</el-scrollbar>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { exportExcel } from '../../provider/index.js' //导出Excel公用方法
export default {
	name: 'clue-applet',
	data() {
		return {
			loadingFlag: false,
			baseUrl: this.$pulick.baseUrl, //默认路径目录  加载静态文件img
			formSearch: {
				companyName: this.$route.query.name!=undefined ? this.$route.query.name : '',
				prop:'createTime',
				order:'desc',
			},
			dataTableEmpty: false,
			tableData: [], //列表  数组
			currentPage: 1, //当前页
			pageSize: 15, //每页  条数
			totalItems: 0, //列表 总数
			multipleSelection: [], //导出参数   数据前  复选框
			exportOneName:"导出所选",
			exportAllName:"导出全部",
			dialogVisible: false, //是否打开编辑弹框
			urlStr:this.$pulick.urlStr,
			ruleForm: {
				reportedName: "",
				reportType:'',
				diqu:'',
				reportAddr:'',
				provinceName: "",
				cityName: "",
				areaName: "",
				reportIndustry:"",
				relationalAppName:"",
				relationalWebsiteName:"",
				relationalPublicAccount:"",
				relationalWeixinAccount:"",
				relationalQqAccount:"",
				relationalPhoneNumber:"",
				relationalBusinessInfo:"",
				financingType:"",
				investCapital:"",
				revenueType:"",
				rate:"",
				caseTime:"",
				involvingInternet:"",
				promisePrincipal:"",
				financingMoney:"",
				participantsNumber:"",
				financingStartTime:"",
				financingBreakTime:"",
				transprovincial:"",
				relationalArea:"",
				remark: "",
				riskLebal:'',
				imageIds: [],
				reportTime: "",
				anonymity:"",
				informer:"",
				informerIdCard:"",
				informerPhoneNumber:"",
				informerEmail:"",
				informerQqAccount:"",
				informerWeixinAccount:"",
			},
		}
	},
	created() {
		this.getPageList(); //加载列表
	},
	mounted() {},
	methods: {
		//加载列表
		getPageList() {
			this.loadingFlag = true; //loading 显示
			this.dataTableEmpty = false;
			this.$provider.get('/wg-ifrRisk/clueApp/getAppReportList', {
				params: {
					companyName: this.formSearch.companyName.replace(/^\s+|\s+$/g, ""),
					prop: this.formSearch.prop,
					order: this.formSearch.order,
					current: this.currentPage,
					size: this.pageSize,
				}
			}).then(res => {
				this.loadingFlag = false; //loading 消失
				this.dataTableEmpty = true;
				this.tableData = res.data.records;
				this.totalItems = res.data.total;
			})
		},
		//处理分页数据的 index
		typeIndex(index) {
			return (this.currentPage - 1) * this.pageSize + index + 1;
		},
		//当前页面  属于第几页
		handleCurrentChange(val) {
			this.currentPage = val;
			this.getPageList(); //加载列表
		},
		//点击查询  按钮
		searchNameFn() {
			this.currentPage = 1; //初始第一页
			this.getPageList(); //加载列表
		},
		//排序
		sortChange(column, prop, order) {
			if (column.order == "ascending") {
				this.formSearch.prop = column.prop;
				this.formSearch.order = "asc";
			} else if (column.order == "descending") {
				this.formSearch.prop = column.prop;
				this.formSearch.order = "desc";
			} else {
				this.formSearch.prop = "";
				this.formSearch.order = "";
			}
			this.getPageList(); //加载列表
		}, 
		//查看
		handleLook(index,item){
			this.dialogVisible = true; //打开弹框
		  this.$provider.get('/wg-ifrRisk/clueApp/getReportInfoById',{
				params:{
					id:item.id,
				}  
		  }).then(res=>{
				this.ruleForm.reportedName = res.data.reportedName;//举报对象名称
				this.ruleForm.reportType = res.data.reportType;//举报对象类型
				this.ruleForm.diqu = res.data.provinceName+' '+res.data.cityName+' '+res.data.areaName;//区域
				this.ruleForm.provinceName = res.data.provinceName;
				this.ruleForm.cityName = res.data.cityName;
				this.ruleForm.areaName = res.data.areaName;
				this.ruleForm.reportAddr = res.data.reportAddr;//详细地址
				this.ruleForm.reportIndustry = res.data.reportIndustry;//举报对象所属行业
				this.ruleForm.relationalAppName = res.data.relationalAppName;//关联APP名称
				this.ruleForm.relationalWebsiteName = res.data.relationalWebsiteName;//关联网站名称
				this.ruleForm.relationalPublicAccount = res.data.relationalPublicAccount;//关联公众号
				this.ruleForm.relationalWeixinAccount = res.data.relationalWeixinAccount;//关联微信号
				this.ruleForm.relationalQqAccount = res.data.relationalQqAccount;//关联QQ号
				this.ruleForm.relationalPhoneNumber = res.data.relationalPhoneNumber;//关联电话
				this.ruleForm.relationalBusinessInfo = res.data.relationalBusinessInfo;//关联主体工商
				this.ruleForm.financingType = res.data.financingType;//集资类型
				this.ruleForm.investCapital = res.data.investCapital;//投入金额
				this.ruleForm.revenueType = res.data.revenueType;//收益类型
				this.ruleForm.rate = res.data.rate;//利率
				this.ruleForm.caseTime = res.data.caseTime;//发案时间
				this.ruleForm.involvingInternet = res.data.involvingInternet;//是否涉及互联网
				//是否承诺保本保息
				if(res.data.promisePrincipal==0){
					this.ruleForm.promisePrincipal = "否";
				} else if(res.data.promisePrincipal==1){
					this.ruleForm.promisePrincipal = "是";
				} else {
					this.ruleForm.promisePrincipal = "";
				}
				this.ruleForm.financingMoney = res.data.financingMoney;//已知集资规模
				this.ruleForm.participantsNumber = res.data.participantsNumber;//已知参与人数
				this.ruleForm.financingStartTime = res.data.financingStartTime;//已知最早开始时间
				this.ruleForm.financingBreakTime = res.data.financingBreakTime;//已知最早暴雷时间
				//是否跨省
				if(res.data.transprovincial==0){
					this.ruleForm.transprovincial = "否";
				} else if(res.data.transprovincial==1){
					this.ruleForm.transprovincial = "是";
				} else {
					this.ruleForm.transprovincial = "";
				}
				this.ruleForm.relationalArea = res.data.relationalArea;//已知涉及地区
				this.ruleForm.remark = res.data.remark;//具体情况说明
				this.ruleForm.riskLebal = res.data.riskLebal;//风险标签
				this.ruleForm.imageIds = res.data.imageUrls;//上传线索
				this.ruleForm.reportTime = res.data.reportTime;//举报时间
				//是否实名
				if(res.data.anonymity==0){
					this.ruleForm.anonymity = "匿名";
				} else if(res.data.anonymity==1){
					this.ruleForm.anonymity = "实名";
				} else {
					this.ruleForm.anonymity = "";
				}
				this.ruleForm.informer = res.data.informer;//举报人
				this.ruleForm.informerIdCard = res.data.informerIdCard;//举报人身份证号
				this.ruleForm.informerPhoneNumber = res.data.informerPhoneNumber;//举报人手机号
				this.ruleForm.informerEmail = res.data.informerEmail;//举报人邮箱
				this.ruleForm.informerQqAccount = res.data.informerQqAccount;//举报人QQ号
				this.ruleForm.informerWeixinAccount = res.data.informerWeixinAccount;//举报人微信号
			})
		},
		
		//表格 每行  复选框选值    导出  删除
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		//导出所选
		exportONE() {
			let chk_value = '';
			this.multipleSelection.forEach(function(f, i) {
				if (i == 0) {
					chk_value = f.id;
				} else {
					chk_value += "," + f.id;
				}
			})
			if (chk_value.length != 0) {
				let myObj = {
					method: 'get',
					url: this.$pulick.urlStr+'/wg-ifrRisk/clueApp/exportReportByIds',
					fileName: '小程序举报',
					params: 'ids='+chk_value
				}
				this.exportOneName = "正在导出中...";
				exportExcel(myObj,this);// 导出Excel公用方法
			} else {
				this.$message({
					message: '请先勾选',
					type: 'warning'
				});
			}
		},
		//导出全部
		exportALLFn() {
			let myObj = {
				method: 'get',
				url: this.$pulick.urlStr+'/wg-ifrRisk/clueApp/exportReportAll',
				fileName: '小程序举报',
				params: 'companyName='+this.formSearch.companyName.replace(/^\s+|\s+$/g, "")
			} 
			this.exportAllName = "正在导出中...";
			exportExcel(myObj,this);// 导出Excel公用方法
		},
	},
}
</script>

<style>
.clue-applet .el-form-item{margin-bottom: 10px;}
</style>
