<template>
	<div class="clue-discovery">
		<el-tabs v-model="activeName">
			<el-tab-pane name="first" :lazy='true'>
				<template slot="label">
					<i class="iconfont icon-duoqudaoxiansuohuiju"></i> 线索主体
				</template>
				<clueSubject></clueSubject>
			</el-tab-pane>
			<el-tab-pane name="second" :lazy='true'>
				<template slot="label">
					<span class="iconfont icon-yuqingjiance-"></span> 负面舆情
				</template>
				<clueSentiAdvertiReport :dataParam="paramFmyq"></clueSentiAdvertiReport>
			</el-tab-pane>
			<el-tab-pane name="three" :lazy='true'>
				<template slot="label">
					<i class="iconfont icon-guanggaotoufangwangluoguanggao"></i> 网络广告
				</template>
				<clueSentiAdvertiReport :dataParam="paramWlgg"></clueSentiAdvertiReport>
			</el-tab-pane>
			<el-tab-pane name="four">
				<template slot="label">
					<i class="iconfont icon-tousujubao"></i> 投诉举报
				</template>
				<clueSentiAdvertiReport :dataParam="paramTsjb"></clueSentiAdvertiReport>
			</el-tab-pane>
			<el-tab-pane name="fif" :lazy='true'>
				<template slot="label">
					<i class="iconfont icon-relation"></i> 股权关联
				</template>
				<clueShares></clueShares>
			</el-tab-pane>
			<el-tab-pane name="six" :lazy='true'>
				<template slot="label">
					<i class="iconfont icon-xiaochengxu3"></i> 小程序举报
				</template>
				<clueApplet></clueApplet>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import clueSubject from '@/views/clue-discovery/clue-subject.vue'
import clueSentiAdvertiReport from '@/views/clue-discovery/clue-senti-adverti-report.vue'
import clueShares from '@/views/clue-discovery/clue-shares.vue'
import clueApplet from '@/views/clue-discovery/clue-applet.vue'
//import { set } from 'vue/types/umd'
export default {
	name: "clue-discovery",
	components: { clueSubject,clueSentiAdvertiReport,clueShares,clueApplet },
	data() {
		return {
			//activeName: this.$route.query.name!=undefined ? "six" : "first",
			activeName:"first",
			paramFmyq: {
				Type: 4,
			},
			paramWlgg: {
				Type: 0,
			},
			paramTsjb: {
				Type: 3,
			},
		}
	},
	created() {},
	mounted() {
		let _this = this;
		setTimeout(function(){
			_this.activeName = _this.$route.query.name!=undefined ? "six" : "first";
		},100)
	},
	methods: {
			
	}
}
</script>

<style lang="stylus"></style>
