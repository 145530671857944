<template>
	<div class="clue-shares">
		<div class="content-box">
			<div class="search-box">
				<el-form :inline="true" :model="formSearch" class="demo-form-inline" label-width="100px">
					<el-form-item label="立案企业">
						<el-input v-model="formSearch.companyName" placeholder="请输入立案企业"></el-input>
					</el-form-item>
					<el-form-item style="text-align: right;" class="fr">
						<el-button type="primary" @click="searchNameFn">检索</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="table-box">
				<el-table border v-loading="loadingFlag" empty-text="加载中..." :data="tableData" :default-sort="{prop: 'clueTime', order: 'descending'}" :row-style="{height:'48px'}" style="width: 100%;">
					<el-table-column type="index" :index="typeIndex" label="序号" width="50"></el-table-column>
					<el-table-column prop="companyName" label="立案企业" width="300" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="registTime" label="立案时间" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="relatedName" label="关联企业" width="300" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="relatedType" label="关系类型"></el-table-column>
					<el-table-column prop="investmentSum" label="投资金额(万元)"></el-table-column>
					<el-table-column prop="investmentProportion" label="投资比例"></el-table-column>
					<el-table-column prop="holdingSum" label="控股金额(万元)"></el-table-column>
					<el-table-column prop="holdingProportion" label="控股比例"></el-table-column>
					<template slot="empty">
						<img v-if="dataTableEmpty" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
					</template>
				</el-table>
				<el-pagination @current-change="handleCurrentChange" :total="totalItems" :current-page="currentPage" :page-size="pageSize" layout="total, prev, pager, next, jumper"></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'clue-shares',
	data() {
		return {
			loadingFlag: false,
			baseUrl: this.$pulick.baseUrl, //默认路径目录  加载静态文件img
			formSearch: {
				companyName: '',
			},
			dataTableEmpty: false,
			tableData: [], //列表  数组
			currentPage: 1, //当前页
			pageSize: 15, //每页  条数
			totalItems: 0, //列表 总数
		}
	},
	created() {
		this.getPageList(); //加载列表
	},
	mounted() {},
	methods: {
		//加载列表
		getPageList() {
			this.loadingFlag = true; //loading 显示
			this.dataTableEmpty = false;
			this.$provider.get('/wg-ifrRisk/clueAnalysis/getClueRelation', {
				params: {
					companyName: this.formSearch.companyName.replace(/^\s+|\s+$/g, ""),
					current: this.currentPage,
					size: this.pageSize,
				}
			}).then(res => {
				this.loadingFlag = false; //loading 消失
				this.dataTableEmpty = true;
				this.tableData = res.data.records;
				this.totalItems = res.data.total;
			})
		},
		//处理分页数据的 index
		typeIndex(index) {
			return (this.currentPage - 1) * this.pageSize + index + 1;
		},
		//当前页面  属于第几页
		handleCurrentChange(val) {
			this.currentPage = val;
			this.getPageList(); //加载列表
		},
		//点击查询  按钮
		searchNameFn() {
			this.currentPage = 1; //初始第一页
			this.getPageList(); //加载列表
		},
	},
}
</script>

<style lang="stylus" scoped="scoped"></style>
