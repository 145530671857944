<template>
	<div class="clue-subject">
		<div class="content-box">
			<div class="search-box">
				<el-form :inline="true" :model="formSearch" class="demo-form-inline" label-width="100px">
					<el-form-item label="线索主体">
						<el-input v-model="formSearch.companyName" placeholder="请输入线索主体"></el-input>
					</el-form-item>
					<el-form-item label="监测状态">
						<el-select v-model="formSearch.riskLevel" clearable>
			 			<el-option label="已监测" value="1"></el-option>
							<el-option label="未监测" value="0"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item style="text-align: right;" class="fr">
						<el-button type="primary" @click="searchNameFn">检索</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="table-box">
				<el-table border v-loading="loadingFlag" empty-text="加载中..." :data="tableData" @sort-change='sortChange' :default-sort="{prop: 'clueTime', order: 'descending'}" :row-style="{height:'48px'}" style="width: 100%;">
					<el-table-column type="index" :index="typeIndex" label="序号" width="50"></el-table-column>
					<el-table-column prop="clueName" label="线索主体" width="400" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							<span v-if='scope.row.isAffirm==1' @click="companyNameLink(scope.row)" class="color-m" style="cursor: pointer;">{{scope.row.clueName}}</span>
							<span v-if='scope.row.isAffirm==0'>{{scope.row.clueName}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="clueTime" label="发现时间" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="negativeOpinionNum" label="负面舆情数" sortable="custom" width="150" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							<span @click="handleEditNumYQ(scope.$index, scope.row,'second')" class="color-m" style="cursor: pointer;">{{scope.row.negativeOpinionNum}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="onlineAdvertisingNum" label="网络广告数" sortable="custom" width="150" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							<span @click="handleEditNumGG(scope.$index, scope.row,'three')" class="color-m" style="cursor: pointer;">{{scope.row.onlineAdvertisingNum}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="complaintReportingNum" label="投诉举报数" sortable="custom" width="150" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							<span @click="handleEditNumTS(scope.$index, scope.row,'four')" class="color-m" style="cursor: pointer;">{{scope.row.complaintReportingNum}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="equityRelatedNum" label="股权关联数" sortable="custom" width="150" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							<span @click="handleEditNumGL(scope.$index, scope.row,'fif')" class="color-m" style="cursor: pointer;">{{scope.row.equityRelatedNum}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="appNum" label="小程序举报数" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="isAffirm" label="监测状态">
						<template slot-scope="scope">
							<el-button v-if='scope.row.isAffirm==0' type="info" size="mini" title="是否纳入监测" @click="handleEditBJ(scope.$index, scope.row)">未监测</el-button>
							<el-button v-if='scope.row.isAffirm==1' type="primary" size="mini">已监测</el-button>
						</template>
					</el-table-column>
					<template slot="empty">
						<img v-if="dataTableEmpty" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
					</template>
				</el-table>
				<el-pagination @current-change="handleCurrentChange" :total="totalItems" :current-page="currentPage" :page-size="pageSize" layout="total, prev, pager, next, jumper"></el-pagination>
			</div>
		</div>
		<el-dialog title="详情列表" :visible.sync="outerVisible" width="80%">
			<div class="table-box">
				<el-table border :data="tableDataXQ" :row-style="{height:'40px'}" @row-click="handleRowXQ">
					<el-table-column type="index" label="序号" width="50"></el-table-column>
					<el-table-column prop="title" label="标题" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							<span class="color-m" style="cursor: pointer;">{{scope.row.title}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="keyWord" label="风险点" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							<span>{{JSON.parse(scope.row.keyWord).join(",")}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="pubtime" label="发布时间" width="200"></el-table-column>
					<el-table-column prop="url" label="来源" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							<a class="color-m" :href="scope.row.url" target="_blank">{{scope.row.url}}</a>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination @current-change="handleCurrentChangeXQ" :total="totalItemsXQ" :current-page="currentPageXQ" :page-size="pageSizeXQ" layout="total, prev, pager, next, jumper"></el-pagination>
			</div>
			<el-dialog title="" :visible.sync="innerVisible" append-to-body width="70%" class="modal-box">
				<div class="modal-body-box" v-loading="tableloading">
					<div class="ellipsis modal-title" v-html="checkInfo.title">{{checkInfo.title}}</div>
					<div class="modal-info">
						<div class="clue-info ellipsis">
							<p>
								<span v-if="checkInfo.extractCompany != ''">线索主体：<b :title="checkInfo.extractCompany">{{checkInfo.extractCompany}}</b></span>
								<span v-if="checkInfo.extractCompany == ''">线索主体：<b>无&nbsp;&nbsp;&nbsp;</b></span>
							</p>
							<p>
								<span v-if="checkInfo.source_type == '9'">信息源：<b>微博</b></span>
								<span v-if="checkInfo.source_type == '10'">信息源：<b>APP</b></span>
								<span v-if="checkInfo.source_type == '5'">信息源：<b>网站</b></span>
								<span v-if="checkInfo.source_type == '8'">信息源：<b>微信</b></span>
								<span v-if="checkInfo.source_type == '6'">信息源：<b>论坛</b></span>
								<span v-if="checkInfo.site != ''">
									<span v-if="checkInfo.source_type=='9' || checkInfo.source_type=='8'">作者：<b>{{checkInfo.site}}</b></span>
									<span v-if="checkInfo.source_type!='9' && checkInfo.source_type!='8'">站点：<b>{{checkInfo.site}}</b></span>
								</span>
								<span v-if="checkInfo.pubtime != ''">发布时间：<b>{{checkInfo.pubtime}}</b></span>
								<span v-if="checkInfo.reviewByName != ''">审核人：<b>{{checkInfo.reviewByName}}</b></span>
								<span v-if="checkInfo.reviewByTime != ''">审核时间：<b>{{checkInfo.reviewByTime}}</b></span>
								<span v-if="checkInfo.url != ''">
									<a :href="checkInfo.url" target="_blank" title="源链接">
										<i class="iconfont icon-lianjie1"></i>
									</a>
								</span>
							</p>
						</div>
					</div>
					<div class="modal-article">
						<div style="width: 100%;height: 600px;">
							<el-scrollbar style="height: 100%;width:100%">
								<span v-html="checkInfo.content">{{checkInfo.content}}</span>
							</el-scrollbar>
						</div>
					</div>
				</div>
			</el-dialog>
		</el-dialog>
		<el-dialog title="详情列表" :visible.sync="guanlianVisible" width="90%">
			<div class="table-box">
				<el-table border :data="tableDataGL" :row-style="{height:'40px'}">
					<el-table-column type="index" label="序号" width="50"></el-table-column>
					<el-table-column prop="companyName" label="立案企业" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="registTime" label="立案时间" width="200"></el-table-column>
					<el-table-column prop="relatedName" label="关联企业"></el-table-column>
					<el-table-column prop="relatedType" label="关系类型" width="100"></el-table-column>
					<el-table-column prop="investmentSum" label="投资金额（万元）" width="150"></el-table-column>
					<el-table-column prop="investmentProportion" label="投资比例" width="100"></el-table-column>
					<el-table-column prop="holdingSum" label="控股金额（万元）" width="150"></el-table-column>
					<el-table-column prop="holdingProportion" label="控股比例" width="100"></el-table-column>
				</el-table>
				<el-pagination @current-change="handleCurrentChangeGL" :total="totalItemsGL" :current-page="currentPageGL" :page-size="pageSizeGL" layout="total, prev, pager, next, jumper"></el-pagination>
			</div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: 'clue-subject',
	data() {
		return {
			loadingFlag: false,
			baseUrl: this.$pulick.baseUrl, //默认路径目录  加载静态文件img
			formSearch: {
				companyName: '',
				riskLevel: '',
				order: 'desc',
				prop: 'clueTime',
			},
			dataTableEmpty: false,
			tableData: [], //列表  数组
			currentPage: 1, //当前页
			pageSize: 15, //每页  条数
			totalItems: 0, //列表 总数
			outerVisible: false, //弹框 外层
			tableDataXQname: "",
			tableType: "",
			tableDataXQ: [], //列表  数组
			currentPageXQ: 1, //当前页
			pageSizeXQ: 10, //每页  条数
			totalItemsXQ: 0, //列表 总数
			innerVisible: false, //弹框 内层
			tableloading: false,
			checkInfo: {
				title: '',
				content: '',
				source_type: '',
				site: "",
				pubtime: '',
				extractCompany: '',
				clueNotarize: '0',
				reviewByName: '',
				reviewByTime: '',
				url: '',
			},
			guanlianVisible: false,
			tableDataGL: [], //列表  数组
			currentPageGL: 1, //当前页
			pageSizeGL: 10, //每页  条数
			totalItemsGL: 0, //列表 总数
		}
	},
	created() {
		this.getPageList(); //加载列表
	},
	mounted() {},
	methods: {
		//加载列表
		getPageList() {
			this.loadingFlag = true; //loading 显示
			this.dataTableEmpty = false;
			this.$provider.get('/wg-ifrRisk/clueMain/getClueInfoList', {
				params: {
				companyName: this.formSearch.companyName.replace(/^\s+|\s+$/g, ""),
					isAffirm: this.formSearch.riskLevel,
					order: this.formSearch.order,
					prop: this.formSearch.prop,
					current: this.currentPage,
					size: this.pageSize
				}
			}).then(res => {
				this.loadingFlag = false; //loading 消失
				this.dataTableEmpty = true;
				this.tableData = res.data.records;
				this.totalItems = res.data.total;
			})
		},
		//处理分页数据的 index
		typeIndex(index) {
			return (this.currentPage - 1) * this.pageSize + index + 1;
		},
		//当前页面  属于第几页
		handleCurrentChange(val) {
			this.currentPage = val;
			this.getPageList(); //加载列表
		},
		//点击查询  按钮
		searchNameFn() {
			this.currentPage = 1; //初始第一页
			this.getPageList(); //加载列表
		},
		//排序
		sortChange(column, prop, order) {
			if (column.order == "ascending") {
				this.formSearch.prop = column.prop;
				this.formSearch.order = "asc";
			} else if (column.order == "descending") {
				this.formSearch.prop = column.prop;
				this.formSearch.order = "desc";
			} else {
				this.formSearch.prop = "";
				this.formSearch.order = "";
			}
			this.getPageList(); //加载列表
		},
		//点击机构   跳转详情页
		companyNameLink(item) {
			let user_xjfj = JSON.parse(sessionStorage.getItem("user_xjfj"));
			user_xjfj['defaultActive'] = 1019;
			sessionStorage.setItem("user_xjfj", JSON.stringify(user_xjfj));
			const icardUrl = this.$router.resolve({
				path: '/institutionalPortraitInfo',
				query: {
					name: item.clueName,
					id: item.companyId
				}
			})
			window.open(icardUrl.href, '_blank'); //跳转新页面
		},

		//编辑  弹框打开   是否纳入监测
		handleEditBJ(index, result) {
			if (confirm("您确定线索主体将纳入监测吗？")) {
				this.$provider.post('/wg-ifrRisk/clueAnalysis/clueReview', {
					id: result.id,
				}).then(res => {
					if (res.code == 200) {
						this.$message({
							message: '已成功纳入监测',
							type: 'success'
						});
						this.getPageList(); //加载列表
					} else {
						this.$message.error('纳入监测失败');
					}
				})
			}
		},
		//审核 数量   弹框   舆情
		handleEditNumYQ(index, item) {
			if (item.negativeOpinionNum != 0) {
				this.outerVisible = true;
				this.tableDataXQname = item.clueName;
				this.tableType = 4;
				this.getClueRelationNum(); //加载审核数 列表
			} else {
				this.$message({
					message: '当前无审核数据，请先进行审核',
					type: 'warning'
				});
			}
		},
		//审核 数量   弹框   广告
		handleEditNumGG(index, item) {
			if (item.onlineAdvertisingNum != 0) {
				this.outerVisible = true;
				this.tableDataXQname = item.clueName;
				this.tableType = 0;
				this.getClueRelationNum(); //加载审核数 列表
			} else {
				this.$message({
					message: '当前无审核数据，请先进行审核',
					type: 'warning'
				});
			}
		},
		//审核 数量   弹框   投诉
		handleEditNumTS(index, item) {
			if (item.complaintReportingNum != 0) {
				this.outerVisible = true;
				this.tableDataXQname = item.clueName;
				this.tableType = 3;
				this.getClueRelationNum(); //加载审核数 列表
			} else {
				this.$message({
					message: '当前无审核数据，请先进行审核',
					type: 'warning'
				});
			}
		},
		//加载审核数 列表
		getClueRelationNum() {
			this.$provider.get('/wg-ifrRisk/clueAnalysis/getClueRelationNum', {
				params: {
					companyName: this.tableDataXQname,
					sign: this.tableType,
					current: this.currentPageXQ,
					size: this.pageSizeXQ
				}
			}).then(res => {
				this.tableDataXQ = res.data.records;
				this.totalItemsXQ = res.data.total;
			})
		},
		//加载审核数 列表   当前页面  属于第几页
		handleCurrentChangeXQ(val) {
			this.currentPageXQ = val;
			this.getClueRelationNum(); //加载列表
		},
		//单条详情
		handleRowXQ(item) {
			this.checkInfo.title = "";
			this.checkInfo.content = "";
			this.checkInfo.source_type = "";
			this.checkInfo.site = "";
			this.checkInfo.pubtime = "";
			this.checkInfo.extractCompany = "";
			this.checkInfo.clueNotarize = "";
			this.checkInfo.reviewByName = "";
			this.checkInfo.reviewByTime = "";
			this.checkInfo.url = "";
			this.innerVisible = true;
			this.tableloading = true;
			this.$provider.get('/wg-ifrRisk/clueAnalysis/getDetailInfo', {
				params: {
					id: item.id,
					sign: this.tableType,
				}
			}).then(res => {
				this.tableloading = false;
				this.checkInfo.title = res.data.title;
				this.checkInfo.content = res.data.content;
				this.checkInfo.source_type = res.data.source_type;
				this.checkInfo.site = res.data.site;
				this.checkInfo.pubtime = res.data.pubtime;
				this.checkInfo.extractCompany = res.data.extractCompany;
				this.checkInfo.clueNotarize = res.data.clueNotarize;
				this.checkInfo.reviewByName = res.data.reviewByName;
				this.checkInfo.reviewByTime = res.data.reviewByTime;
				this.checkInfo.url = res.data.source_url;
			})
		},
		//关联 数  弹框
		handleEditNumGL(index, item) {
			if (item.equityRelatedNum != 0) {
				this.tableDataXQname = item.clueName;
				this.getGuanlianTable(); //封装 列表
			} else {
				this.$message({
					message: '当前无数据',
					type: 'warning'
				});
			}
		},
		//封装 列表
		getGuanlianTable() {
			this.$provider.get('/wg-ifrRisk/clueAnalysis/getClueRelation', {
				params: {
					relatedName: this.tableDataXQname,
					current: this.currentPageGL,
					size: this.pageSizeGL
				}
			}).then(res => {
				this.tableDataGL = res.data.records;
				this.totalItemsGL = res.data.total;
				this.guanlianVisible = true;
			})
		},
		handleCurrentChangeGL(val) {
			this.currentPageGL = val;
			this.getGuanlianTable(); //加载列表
		},
	},
}
</script>

<style lang="stylus" scoped="scoped"></style>
