<template>
	<div class="clue-senti-adverti-report">
		<div class="content-box">
			<div class="search-box">
				<el-form :inline="true" :model="formSearch" class="demo-form-inline" label-width="100px">
					<el-form-item label="线索主体">
						<el-input v-model="formSearch.companyName" placeholder="请输入线索主体"></el-input>
					</el-form-item>
					<el-form-item label="信息源">
						<el-select v-model="formSearch.sourceType" clearable>
							<el-option label="新闻" value="4"></el-option>
							<el-option label="论坛" value="6"></el-option>
							<el-option label="微信" value="8"></el-option>
							<el-option label="微博" value="9"></el-option>
							<el-option label="APP" value="10"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item style="text-align: right;" class="fr">
						<el-button type="primary" @click="searchListFn()">检索</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="content-box" v-loading="loadingFlag">
				<div class="enterprise-box" v-show="!showHide" style="height: 779px;">
					<div class="opinion-list" v-for="(item,index) in tableData" :key="index">
						<h5 @click="handleEdit(item, index)">
							<span class="index-num">{{(currentPage - 1) * pageSize + index + 1}}</span>
							<span class="opinion-title ellipsis" v-html="item.title">{{item.title}}</span>
						</h5>
						<div class="opinion-text">
							<p class="ellipsisTwo" v-html="item.content">{{item.content}}</p>
						</div>
						<div class="opinion-bottom">
							<div class="article-source">
								<span v-if="item.sourceTpye == '4'"><i class="iconfont icon-xinwen"></i>新闻</span>
								<span v-if="item.sourceTpye == '9'"><i class="iconfont icon-weibo2"></i>微博</span>
								<span v-if="item.sourceTpye == '10'"><i class="iconfont icon-APP"></i>APP</span>
								<span v-if="item.sourceTpye == '5'"><i class="iconfont icon-wangzhan"></i>博客</span>
								<span v-if="item.sourceTpye == '8'"><i class="iconfont icon-weixin1"></i>微信</span>
								<span v-if="item.sourceTpye == '6'"><i class="iconfont icon-icon_luntan-mian"></i>论坛</span>
							</div>
							<div class="article-time"><b>发布时间：</b>{{item.pubtime}}</div>
							<div class="article-time" v-if="item.reviewByName != null"><b>审核人：</b>{{item.reviewByName}}</div>
							<div class="article-time" v-if="item.reviewByTime != null"><b>审核时间：</b>{{item.reviewByTime}}</div>
							<div class="article-time ellipsis" style="width: 10%;text-align: left;" v-if="item.area != null"><b>所属地区：</b>{{item.area}}</div>
							<div class="article-time ellipsis" style="width: 20%;text-align: left;" v-if="item.riskPoint != ''"><b>风险点：</b>{{item.riskPoint}}</div>
							<div class="article-time ellipsis" style="width: 30%;text-align: left;" v-if="item.extractCompany != null"><b>线索主体：</b>{{item.extractCompany}}</div>
							<div class="article-href">
								<a :href="item.url" target="_blank" title="源链接">
									<i class="iconfont icon-lianjie1"></i>
								</a>
							</div>
						</div>
					</div>
				</div>
				<div class="enterprise-box" v-show="showHide"><img :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;"></div>
				<el-pagination @current-change="handleCurrentChange" :total="totalItems" :current-page="currentPage" :page-size="pageSize" layout="total, prev, pager, next, jumper"></el-pagination>
			</div>
		</div>
		<el-dialog title="" :visible.sync="outerVisible" width="70%" class="modal-box">
			<div class="modal-body-box" v-loading="loadingFlag1">
				<div class="ellipsis modal-title" v-html="checkInfo.title">{{checkInfo.title}}</div>
				<div class="modal-info">
					<div class="clue-info ellipsis">
						<p>
							<span v-if="checkInfo.extractCompany != ''">线索主体：<b :title="checkInfo.extractCompany">{{checkInfo.extractCompany}}</b></span>
							<span v-if="checkInfo.extractCompany == ''">线索主体：<b>无&nbsp;&nbsp;&nbsp;</b></span>
						</p>
						<p>
							<span v-if="checkInfo.source_type == '9'">信息源：<b>微博</b></span>
							<span v-if="checkInfo.source_type == '10'">信息源：<b>APP</b></span>
							<span v-if="checkInfo.source_type == '5'">信息源：<b>网站</b></span>
							<span v-if="checkInfo.source_type == '8'">信息源：<b>微信</b></span>
							<span v-if="checkInfo.source_type == '6'">信息源：<b>论坛</b></span>
							<span v-if="checkInfo.site != ''">
								<span v-if="checkInfo.source_type=='9' || checkInfo.source_type=='8'">作者：<b>{{checkInfo.site}}</b></span>
								<span v-if="checkInfo.source_type!='9' && checkInfo.source_type!='8'">站点：<b>{{checkInfo.site}}</b></span>
							</span>
							<span v-if="checkInfo.pubtime != ''">发布时间：<b>{{checkInfo.pubtime}}</b></span>
							<span v-if="checkInfo.reviewByName != ''">审核人：<b>{{checkInfo.reviewByName}}</b></span>
							<span v-if="checkInfo.reviewByTime != ''">审核时间：<b>{{checkInfo.reviewByTime}}</b></span>
							<span v-if="checkInfo.url != ''">
								<a :href="checkInfo.url" target="_blank" title="源链接">
									<i class="iconfont icon-lianjie1"></i>
								</a>
							</span>
						</p>
					</div>
				</div>
				<div class="modal-article">
					<div style="width: 100%;height: 600px;">
						<el-scrollbar style="height: 100%;width:100%">
							<span v-html="checkInfo.content">{{checkInfo.content}}</span>
						</el-scrollbar>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: 'clue-senti-adverti-report',
	props: {
		dataParam: {
			type: [Object]
		},
	},
	data() {
		return {
			baseUrl: this.$pulick.baseUrl, //默认路径目录  加载静态文件img
			formSearch: {
				companyName: '',
				sourceType: '',
			},
			loadingFlag: false,
			loadingFlag1: false,
			showHide: false,
			tableData: [], //列表  数组
			currentPage: 1, //当前页
			pageSize: 10, //每页  条数
			totalItems: 0, //列表 总数
			outerVisible: false, //弹框  外层
			checkInfo: {
				areaAuditId: "",
				title: '',
				content: '',
				source_type: '',
				site: "",
				pubtime: '',
				extractCompany: '',
				reviewByName: '',
				reviewByTime: '',
				url: '',
			},
		}
	},
	created() {
		this.getPageList(); //加载列表
	},
	mounted() {},
	methods: {
		//点击查询  按钮
		searchListFn() {
			this.currentPage = 1;
			this.getPageList(); //加载列表
		},
		//加载列表
		getPageList() {
			this.loadingFlag = true; //loading 显示
			this.tableData = [];
			this.showHide = false;
			this.$provider.get('/wg-ifrRisk/clueMain/getNegativeList', {
				params: {
					extractCompany: this.formSearch.companyName.replace(/^\s+|\s+$/g, ""),
					sign: this.dataParam.Type,
					sourceType: this.formSearch.sourceType,
					clueNotarize: 1,
					current: this.currentPage,
					size: this.pageSize
				}
			}).then(res => {
				this.loadingFlag = false; //loading 消失
				if (res.data.total != 0) {
					this.tableData = res.data.records;
					this.totalItems = res.data.total;
					this.showHide = false;
				} else {
					this.showHide = true;
				}
			})
		},
		//当前页面  属于第几页
		handleCurrentChange(val) {
			this.currentPage = val;
			this.getPageList(); //加载列表
		},
		//详情审核  外框
		handleEdit(item, index) {
			this.outerVisible = true; //打开弹框  外层
			this.checkInfo.id = item.id;
			this.checkInfo.areaAuditId = item.areaAuditId;
			this.checkInfo.title = "";
			this.checkInfo.content = "";
			this.checkInfo.sourceType = "";
			this.checkInfo.site = "";
			this.checkInfo.pubtime = "";
			this.checkInfo.extractCompany = "";
			this.checkInfo.reviewByName = "";
			this.checkInfo.reviewByTime = "";
			this.checkInfo.url = "";
			this.getDetailInfo(item); //获取详情
		},
		//获取详情
		getDetailInfo(item) {
			this.loadingFlag1 = true; //loading 显示
			this.$provider.get('/wg-ifrRisk/clueAnalysis/getDetailInfo', {
				params: {
					id: item.id,
					provinceId: item.provinceId,
					cityId: item.cityId,
					sign: this.dataParam.Type,
				}
			}).then(res => {
				this.loadingFlag1 = false; //loading 消失
				this.checkInfo.title = res.data.title;
				this.checkInfo.content = res.data.content;
				this.checkInfo.source_type = res.data.source_type;
				this.checkInfo.site = res.data.site;
				this.checkInfo.pubtime = res.data.pubtime;
				this.checkInfo.extractCompany = res.data.extractCompany;
				this.checkInfo.reviewByName = res.data.reviewByName;
				this.checkInfo.reviewByTime = res.data.reviewByTime;
				this.checkInfo.url = res.data.source_url;
			})
		},
	}
}
</script>

<style lang="stylus" scoped="scoped"></style>
